import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";

import { HOME_HEALTH_APP_EVENTS } from "../../lib/events";
import { type CaseFilters, type CasesResponse, casesResponseSchema } from "../../types";

export const BOOKED_CASES_URL = `${environmentConfig.REACT_APP_HOME_HEALTH_API_URL}/v1/cases`;

export function getBookedCasesQueryKey(filters: CaseFilters = {}) {
  const filtersWithBooked = { ...filters, filter: { booked: true, ...filters.filter } };
  return [BOOKED_CASES_URL, filtersWithBooked];
}

export function useBookedCases(
  filters: CaseFilters,
  options: UseGetQueryOptions<CasesResponse> = {}
) {
  const filtersWithBooked = { ...filters, filter: { booked: true, ...filters.filter } };
  return useGetQuery({
    url: BOOKED_CASES_URL,
    responseSchema: casesResponseSchema,
    queryParams: filtersWithBooked,
    meta: {
      logErrorMessage: HOME_HEALTH_APP_EVENTS.GET_BOOKED_CASES_FAILURE,
    },
    retry: 0,
    staleTime: 1000 * 60 * 1,
    refetchInterval: 1000 * 60 * 1,
    ...options,
  });
}
