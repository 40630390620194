import { logError } from "@src/appV2/lib/analytics";
import { type ErrorInfo, type ReactNode } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "./ErrorFallback";

interface ErrorBoundaryProps {
  children: ReactNode;
}

function reportErrorToDataDog(error: Error, errorInfo: ErrorInfo): void {
  /**
   * Taken from https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
   */
  const renderingError = new Error(error.message);

  renderingError.name = `ReactRenderingError`;
  renderingError.stack = errorInfo.componentStack;
  // We need "as any" here because although DataDog understands "cause"
  // for TypeScript is not a valid property of Error.
  (renderingError as any).cause = error;

  logError("React Error Boundary", { error: renderingError });
}

export function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children } = props;

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, errorInfo) => {
        reportErrorToDataDog(error, errorInfo);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
