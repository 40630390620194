import { CircularProgress } from "@mui/material";
import { lazy, Suspense } from "react";

const FindCases = lazy(
  async () =>
    await import("./FindCases").then(async (module) => {
      return {
        default: module.FindCases,
      };
    })
);

export function FindCasesPage() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <FindCases />
    </Suspense>
  );
}
