import { appV2EventPrefix } from "@src/appV2/lib/analytics/events";

export const HOME_HEALTH_USER_EVENTS = {
  OPEN_CASES_VIEW: `${appV2EventPrefix} Home Health Open Cases View`,
  BOOKED_CASES_VIEW: `${appV2EventPrefix} Home Health Booked Cases View`,
  VISIT_BOOKED: `${appV2EventPrefix} Home Health Visit Booked`,
  VISIT_LOGGED: `${appV2EventPrefix} Home Health Visit Logged`,
  VISIT_DROPPED: `${appV2EventPrefix} Home Health Visit Dropped`,
  SIGNATURE_SUBMITTED: `${appV2EventPrefix} Home Health Signature Submitted`,
};

export const HOME_HEALTH_APP_EVENTS = {
  GET_OPEN_CASES_FAILURE: `${appV2EventPrefix} Home Health Failed to load open cases`,
  GET_BOOKED_CASES_FAILURE: `${appV2EventPrefix} Home Health Failed to load booked cases`,
  GET_SIGNATURES_FAILURE: `${appV2EventPrefix} Home Health Failed to load signatures`,
};
