import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";

const CHAT_CHANNEL_QUERY_LIMIT = 100;

export const GET_CHAT_CHANNELS_QUERY_KEY = ["chat-channels"];

export function useGetChatChannels(
  options: UseQueryOptions<{ isSdkInitialized: boolean; channels: SendBird.GroupChannel[] }> = {}
): UseQueryResult<{ isSdkInitialized: boolean; channels: SendBird.GroupChannel[] }> {
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdSelectors.getSdk(sendBirdState);

  return useQuery({
    queryKey: GET_CHAT_CHANNELS_QUERY_KEY,
    queryFn: async () => {
      if (!isDefined(sdk?.GroupChannel)) {
        return { isSdkInitialized: false, channels: [] };
      }

      const query = sdk?.GroupChannel.createMyGroupChannelListQuery();

      if (!isDefined(query)) {
        return { isSdkInitialized: true, channels: [] };
      }

      query.includeEmpty = true;
      query.memberStateFilter = "joined_only";
      query.order = "latest_last_message";
      query.limit = CHAT_CHANNEL_QUERY_LIMIT;
      const channels = await query.next();
      return { isSdkInitialized: true, channels };
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_CHAT_CHANNELS_FAILURE,
    },
    ...options,
  });
}
