import { CircularProgress } from "@mui/material";
import { lazy, Suspense } from "react";

const BookedCases = lazy(
  async () =>
    await import("./BookedCases").then(async (module) => {
      return {
        default: module.BookedCases,
      };
    })
);

export function TemporaryBookedCasesWithMockServer() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <BookedCases />
    </Suspense>
  );
}
