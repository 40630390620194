import { InternalLink } from "@clipboard-health/ui-react";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { Badge, IconButton } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";

import { APP_V2_USER_EVENTS, logEvent } from "../../lib/analytics";
import { useGetChatChannels } from "./api/useGetChatChannels";

interface MessagesButtonProps {
  agentId?: string;
}

export function FacilityChatMessagesButton(props: MessagesButtonProps) {
  const { agentId } = props;

  const { data } = useGetChatChannels();
  const chatChannels = data?.channels ?? [];

  const unreadChannelCount = chatChannels.reduce((memo, channel) => {
    const { unreadMessageCount } = channel;
    return unreadMessageCount > 0 ? memo + unreadMessageCount : memo;
  }, 0);

  const unreadChanelCountLabel = unreadChannelCount <= 99 ? unreadChannelCount : "99+";

  return (
    <Badge
      overlap="circular"
      badgeContent={unreadChannelCount > 0 ? unreadChanelCountLabel : undefined}
      color="error"
      sx={{
        "& .MuiBadge-badge": {
          top: `8px`,
        },
      }}
    >
      <IconButton
        aria-label="Open chat"
        color="inherit"
        component={InternalLink}
        to={DeprecatedGlobalAppV1Paths.CHAT_CHANNELS_LIST}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.MESSAGES_ICON_CLICKED, {
            agentId,
          });
        }}
      >
        <ForumOutlinedIcon sx={{ color: "white" }} />
      </IconButton>
    </Badge>
  );
}
