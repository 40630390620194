import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { solveUnpaidBreaksStageWithTargeting } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export const solveWorkedUnpaidBreaksStageResponseSchema = z.object({
  solveUnpaidBreaksStageWithTargeting,
});

export type SolveWorkedUnpaidBreaksStageResponse = z.infer<
  typeof solveWorkedUnpaidBreaksStageResponseSchema
>;

// TODO(clipboardhealth.atlassian.net/browse/TX-761): Remove file, function and its usage after the complete rollout and flag removal
export function useGetSolveUnpaidBreaksStage(
  facilityId: string,
  options: UseGetQueryOptions<SolveWorkedUnpaidBreaksStageResponse> = {}
): UseQueryResult<SolveWorkedUnpaidBreaksStageResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/facilityprofile/${facilityId}/solveWorkedUnpaidBreaksStageWithTargeting`,
    responseSchema: solveWorkedUnpaidBreaksStageResponseSchema,
    meta: { logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_SOLVE_UNPAID_WORKED_BREAKS_STAGE },
    ...options,
    enabled: options?.enabled ?? true,
  });
}
